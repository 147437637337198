import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { Loader2Icon } from 'lucide-react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-none focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 disable:bg-gray-brand2 w-max',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/80',
        'outline-destructive':
          'border border-destructive-foreground bg-transparent text-destructive-foreground hover:text-destructive-foreground/80',
        outline:
          'border border-gray-brand3 bg-background hover:bg-pink-brand3 hover:text-accent-foreground',
        input:
          'flex h-9 w-full duration-500 ease-in-out rounded-sm border border-gray-brand2 bg-white px-3 py-1 text-sm transition text-black-brand focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand focus:border-purple-brand disabled disabled:bg-gray-brand4',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        'outline-secondary': 'border border-secondary bg-transparent text-secondary ',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-6 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
      rounded: {
        default: 'rounded-md',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      rounded: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  loadingText?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      loadingText = '',
      children,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), 'disabled:cursor-not-allowed')}
        ref={ref}
        disabled={loading || props.disabled}
        type={type}
        {...props}
      >
        {loading ? (
          <>
            <Loader2Icon size={16} strokeWidth={1.5} className="animate-spin mr-1" />
            {loadingText}
          </>
        ) : (
          children
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
